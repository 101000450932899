<template>
    <div ref="getHeight">
        <div class="emptyMinHeight">
            <div>
                <div class="mktReader position-relative mb-4 bg-white border br-18 p-3" v-if="Object.keys(store.assetDetail).length">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                        <div class="colDiv">
                            <div class="d-flex align-items-center flex-wrap">
                                <div class="vueRisk">
                                    <span class="d-flex align-items-center justify-content-center me-2">
                                        <v-lazy-image width="30" height="30" class="euCurrency" :src="
                    static_vars.marketImageSURL +
                    store.assetDetail.symbol.toUpperCase().replace(/\//g, '') +
                    '.svg'
                    " :alt="store.assetDetail.symbol" :title="store.assetDetail.symbol" @error="handleImgErr($event)" />
                                    </span>
                                </div>
                                <div class="text-start">
                                    <h6 class="d-block bold mb-0 f-22">{{ store.assetDetail.symbol }}</h6>
                                    <!-- <p class="line-1 mb-0 midgray f-14 medium">TSLA, NSDAQ</p> -->
                                </div>
                                <div class="ms-md-3 my-3 my-md-0">
                                    <a href="javascript:void(0)"
                                        class="ms-auto bold button zulu_btn rounded border-button small-button d-inline-flex align-items-center"
                                        :class="{ 'disabled': store.customerDetail?.readOnly }"
                                        @click="AddWatchList(store.assetDetail)">
                                        <vue-feather class="me-1" type="star" size="14"
                                            :class="[{ filled: isFollowed(store.assetDetail.id) }]"></vue-feather>
                                        {{
                                        isFollowed(store.assetDetail.id)
                                        ? $t("marketDetail.text1")
                                        : $t("marketDetail.text2")
                                        }}
                                    </a>
                                </div>
                            </div>
                            <div class="highLow">
                                <ul class="rateChart d-flex align-items-center flex-wrap"
                                    v-if="Object.keys(store.assetDetail).length">
                                    <li>
                                        <span class="f-14 midgray me-1">{{
                                            $t("marketDetail.text3")
                                            }}</span>
                                        <p class="medium mb-0">
                                            {{
                                            store.assetDetail.dailyChart &&
                                            store.assetDetail.dailyChart.length
                                            ? store.assetDetail.dailyChart[
                                            store.assetDetail.dailyChart.length - 1
                                            ].priceOpen
                                            : store.assetDetail.hourlyChart &&
                                            store.assetDetail.hourlyChart.length
                                            ? store.assetDetail.hourlyChart[
                                            store.assetDetail.hourlyChart.length - 1
                                            ].priceOpen
                                            : 0
                                            }}
                                        </p>
                                    </li>
                                    <li>
                                        <span class="f-14 midgray me-1">{{
                                            $t("marketDetail.text4")
                                            }}</span>
                                        <p class="medium green mb-0">
                                            {{
                                            store.assetDetail.dailyChart &&
                                            store.assetDetail.dailyChart.length
                                            ? store.assetDetail.dailyChart[
                                            store.assetDetail.dailyChart.length - 1
                                            ].priceHigh
                                            : store.assetDetail.hourlyChart &&
                                            store.assetDetail.hourlyChart.length
                                            ? store.assetDetail.hourlyChart[
                                            store.assetDetail.hourlyChart.length - 1
                                            ].priceHigh
                                            : 0
                                            }}
                                        </p>
                                    </li>
                                    <li>
                                        <span class="f-14 midgray me-1">{{
                                            $t("marketDetail.text5")
                                            }}</span>
                                        <p class="medium red mb-0">
                                            {{
                                            store.assetDetail.dailyChart &&
                                            store.assetDetail.dailyChart.length
                                            ? store.assetDetail.dailyChart[
                                            store.assetDetail.dailyChart.length - 1
                                            ].priceLow
                                            : store.assetDetail.hourlyChart &&
                                            store.assetDetail.hourlyChart.length
                                            ? store.assetDetail.hourlyChart[
                                            store.assetDetail.hourlyChart.length - 1
                                            ].priceLow
                                            : 0
                                            }}
                                        </p>
                                    </li>
                                    <li>
                                        <span class="f-28 bold me-1 minCalaContent">{{
                                            store.assetDetail.symbol in store.allPrices &&
                                            Object.keys(store.allPrices[store.assetDetail.symbol]).length
                                            ? store.allPrices[store.assetDetail.symbol].buyPrice
                                            : store.assetDetail.currentPrice
                                            }}<!-- <sub class="f-16">09</sub> --></span>
                                        <p class="medium mb-0 px-1" :class="
                        parseFloat(store.assetDetail.lastChangePercentage) >= 0.0
                        ? 'greenView'
                        : 'redView'
                    ">
                                            {{
                                            parseFloat(store.assetDetail.lastChangePercentage) >= 0.0
                                            ? "+"
                                            : ""
                                            }}{{
                                            parseFloat(store.assetDetail.lastChangePercentage).toFixed(2)
                                            }}%
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end">
                            <a href="javascript:void(0)"
                                class="button buyBtn fillBtn zulu_btn px-md-4 small-button me-2 "
                                :class="{ 'disabled': store.customerDetail?.readOnly }"
                                @click="checKLogin('buy')">{{ $t("home.text78") }}</a>
                            <a href="javascript:void(0)"
                                class="button sellBtn fillBtn zulu_btn px-md-4 small-button"
                                :class="{ 'disabled': store.customerDetail?.readOnly }"
                                @click="checKLogin('sell')">{{ $t("home.text79") }}</a>
                        </div>
                    </div>
                    <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                    <Nodata v-if="
                    !store.singleLoading &&
                    dateDropSelected.key != 1 &&
                    !store.assetDetail.dailyChart.length
                    "></Nodata>
                    <Nodata v-if="
                    !store.singleLoading &&
                    dateDropSelected.key == 1 &&
                    !store.assetDetail.hourlyChart.length
                    "></Nodata>
                    <div class="chartIQ" :id="'AssetChart' + store.assetDetail.id"
                        style="height: 400px; width: 100%"></div>
                    <ul class="d-flex align-items-center justify-content-evenly" v-if="
                        !(
                            (dateDropSelected.key != 1 && !store.assetDetail.dailyChart.length) ||
                            (dateDropSelected.key == 1 && !store.assetDetail.hourlyChart.length)
                        )
                        ">
                        <li v-for="list in dateDrop" :key="list.key">
                            <a class="f-14 gray selectDay" @click.prevent="
                            dateDropSelected = list;
                            changeChart(dateDropSelected.key);
                            " :class="[{ active: dateDropSelected.value == list.value }]" href="javascript:void(0);">{{
                                list.value }}</a>
                        </li>
                    </ul>
                </div>
                <div class="blend-empty bg-white boxed p-3" v-else>
                    <Nodata/>
                    <!-- <v-lazy-image class="d-block w-100" src="/assets/images/loader-animate.svg" :alt="$t('copyTrade.copyTradecontent18')" /> -->
                </div>
                <div class="myTrade" v-if="store.user.access_token">
                    <h5 class="mb-3 f-22 medium">
                        {{ $t("marketDetail.text6") }} {{ store.assetDetail.symbol || "" }}
                        {{ $t("marketDetail.text7") }}
                    </h5>
                    <div class="bg-white boxed cardBorder p-0">
                        <ul id="tabs-nav"
                            class="zuluTabs smallTab d-flex align-items-center border-bottom mb-0">
                            <li :class="[{ active: tradeType == 'open' }]">
                                <a href="javascript:void(0)" @click="
                tradeType = 'open';
                getCurrecnyTrades();
                ">{{ $t("marketDetail.text3") }}</a>
                            </li>
                            <li :class="[{ active: tradeType == 'pending' }]">
                                <a href="javascript:void(0)" @click="
                tradeType = 'pending';
                getCurrecnyTrades();
                ">{{ $t("marketDetail.text8") }}</a>
                            </li>
                            <li :class="[{ active: tradeType == 'history' }]">
                                <a href="javascript:void(0)" @click="
                tradeType = 'history';
                getCurrecnyTrades();
                ">{{ $t("marketDetail.text9") }}</a>
                            </li>
                        </ul>
                        <div class="dataTable table-responsive position-relative">
                            <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                            <table id="example" class="table currencyTable" v-if="tradeType == 'open'">
                                <thead>
                                    <tr>
                                        <th class="ps-md-4 pointer desc">
                                            {{ $t("marketDetail.text10") }}
                                        </th>
                                        <th class="pointer desc">{{ $t("marketDetail.text11") }}</th>
                                        <th class="pointer desc">{{ $t("marketDetail.text12") }}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="
                Object.keys(store.assetTradesList).length &&
                store.assetTradesList.content &&
                store.assetTradesList.content.length
                ">
                                    <tr v-for="(item, key) in store.assetTradesList.content" :key="key">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="nameView">
                                                    <div class="d-flex align-items-center">
                                                        <p class="mb-0 f-14 medium">{{ item.currencyName }}</p>
                                                        <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <p class="mb-0 midgray f-11 medium">
                                                            {{ dateFormat(item.dateTime) || "-" }},
                                                            {{ timeFormat(item.dateTime) || "-" }}
                                                        </p>
                                                        <span class="status" :class="[
                            { buy: item.tradeType == 'BUY' },
                            { sell: item.tradeType == 'SELL' },
                            ]">{{ item.tradeType }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td :class="parseFloat(item.floatingPnl) >= 0.0 ? 'green' : 'red'">
                                            {{ COMMON.getPNL(item) > 0 ? "" : "-" }}${{
                                            Math.abs(COMMON.getPNL(item))
                                            }}
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)"
                                                :class="{ 'disabled': store.customerDetail?.readOnly }"
                                                @click="store.brokerAccountSettings?.ignoreConfirmationPopupWhenClosingPosition ? closeTrade(item) : exitObject=item"><vue-feather class="red" size="16"
                                                    type="trash-2" title="Close Trade"></vue-feather></a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3">
                                            <Nodata></Nodata>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table id="example" class="table currencyTable" v-if="tradeType == 'pending'">
                                <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                                <thead>
                                    <tr>
                                        <th class="ps-md-4 pointer desc">
                                            {{ $t("marketDetail.text10") }}
                                        </th>
                                        <th class="pointer desc">{{ $t("marketDetail.text13") }}</th>
                                    </tr>
                                </thead>
                                <tbody v-if="
                Object.keys(store.assetPendindTradesList).length &&
                store.assetPendindTradesList.content &&
                store.assetPendindTradesList.content.length
                ">
                                    <tr v-for="(item, key) in store.assetPendindTradesList.content" :key="key">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="nameView">
                                                    <div class="d-flex align-items-center">
                                                        <p class="mb-0 f-14 medium">{{ item.currencyName }}</p>
                                                        <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <p class="mb-0 midgray f-11 medium">
                                                            {{ dateFormat(item.dateTime) || "-" }},
                                                            {{ timeFormat(item.dateTime) || "-" }}
                                                        </p>
                                                        <span class="status" :class="[
                            { buy: item.tradeType == 'BUY' },
                            { sell: item.tradeType == 'SELL' },
                            ]">{{ item.tradeType }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{ item.entryRate }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="2">
                                            <Nodata></Nodata>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table id="example" class="table currencyTable" v-if="tradeType == 'history'">
                                <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                                <thead>
                                    <tr>
                                        <th class="ps-md-4 pointer desc">
                                            {{ $t("marketDetail.text10") }}
                                        </th>
                                        <th class="pointer desc">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody v-if="
                Object.keys(store.assetTradesHistoryList).length &&
                store.assetTradesHistoryList.content &&
                store.assetTradesHistoryList.content.length
                ">
                                    <tr v-for="(item, key) in store.assetTradesHistoryList.content" :key="key">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="nameView">
                                                    <div class="d-flex align-items-center">
                                                        <p class="mb-0 f-14 medium">{{ item.currency }}</p>
                                                        <!-- <span class="cfdTrade ms-1">CFD</span> -->
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <p class="mb-0 midgray f-11 medium">
                                                            {{ dateFormat(item.dateClosed) || "-" }},
                                                            {{ timeFormat(item.dateClosed) || "-" }}
                                                        </p>
                                                        <span class="status" :class="[
                            { buy: item.tradeType == 'BUY' },
                            { sell: item.tradeType == 'SELL' },
                            ]">{{ item.tradeType }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="mb-0 medium f-14 pnl d-flex align-items-center">
                                                <span class="me-1">{{ $t("marketDetail.text14") }} :
                                                </span><span
                                                    :class="parseFloat(item.grossPnl) >= 0.0 ? 'green' : 'red'">{{
                                                    formatPrice(item.grossPnl) }}</span>
                                            </p>
                                            <p class="mb-0 medium f-14 pnl d-flex align-items-center">
                                                <span class="me-1">{{ $t("marketDetail.text15") }} : </span>
                                                <span
                                                    :class="parseFloat(item.grossPnl) >= 0.0 ? 'green' : 'red'">{{
                                                    item.lots }}
                                                </span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="2">
                                            <Nodata></Nodata>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Exit trade confirm -->
        <div class="modal show fade" style="display: block;" v-if="Object.keys(exitObject).length">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <h5 class="modal-title">{{$t('changes.text29')}} <span class="secondary">{{exitObject.currencyName}}</span></h5>
                        <button type="button" class="btn-close" @click="exitObject={}"></button>
                    </div>
                    <div class="modal-body p-4 border-bottom text-center">
                        <!-- <v-lazy-image height="250" width="250" class="d-block mx-auto mb-4" src="/assets/images/card-icons/congratulationAdded.gif" alt="Payment Method" /> -->
                        <p class="mb-0">{{$t('changes.text30')}}</p>
                    </div>
                    <div class="modal-footer border-0 justify-content-end">
                        <a href="javascript:void(0)" class="button border-button rounded border-button zulu_btn d-flex" @click="exitObject={}">{{$t('changes.text31')}}</a>
                        <a href="javascript:void(0)" class="button fillBtn zulu_btn d-flex" @click="closeTrade(exitObject)">{{$t('changes.text32')}} </a>
                    </div>
                </div>
            </div>
        </div>
        <buySell v-if="openPopup" />
        <loginPopup v-if="showLoginPopup"></loginPopup>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import moment from "moment";
    import buySell from "./buy-sell/buy-sell.vue";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                taskTab: "Market",
                CurrencyID: "",
                errImage: [],
                dateDrop: [
                    { key: 1, value: "1D" },
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "YTD" },
                ],
                dateDropSelected: { key: 365, value: "1Y" },
                tradeType: "open",
                openPopup: "",
                detail: {},
                showLoginPopup: false,
                showNews: {},
                height: '',
                exitObject: {}
            };
        },
        props: ['marketSelectedProp'],
        computed:{
            marketSelected(){
                return this.marketSelectedProp
            }
        },
        components: {
            buySell,
        },
        watch: {
            "marketSelected"() {
                this.CurrencyID = this.marketSelected.id;
                this.getCurrecnyDetail();
                this.dateDropSelected  = { key: 365, value: "1Y" };
                if (this.store.user.access_token) {
                    this.getCurrecnyTrades();
                    //this.getMarketClose()
                }
            },
            "store.providerOpenTrades.content"() {
                if (
                    this.store.providerOpenTrades.content &&
                    this.store.providerOpenTrades.content.length
                ) {
                    let currency = this.marketSelected.symbol.includes("-")
                        ? this.marketSelected.symbol.split("-").join("/")
                        : this.marketSelected.symbol;
                    let particularAssetdata = this.store.providerOpenTrades.content.filter(
                        (i) =>
                            i.brokerAccountId == this.store.userSelectedAccount.brokerAccountId &&
                            i.currencyName == currency
                    );
                    this.store.$patch({ assetTradesList: { content: particularAssetdata } });
                } else {
                    this.store.$patch({ assetTradesList: { content: [] } });
                }
            },
        },
        methods: {
            showHideText(list) {
                if (Object.keys(list).length) {
                    if (Object.keys(this.showNews).length && list._id == this.showNews._id) {
                        if (list.source == "FXS" && list.text) {
                            return list.text;
                        } else if (list.description) {
                            return list.description;
                        } else {
                            if (list.source == "TE") {
                                return list.description;
                            } else {
                                return list.summary;
                            }
                        }
                    } else {
                        if (list.source == "FXS" && list.text) {
                            return `${list.text.substring(0, 50)}...`;
                        } else if (list.description) {
                            return `${list.description.substring(0, 50)}...`;
                        } else {
                            if (list.source == "TE") {
                                return `${list.description.substring(0, 50)}...`;
                            } else {
                                return `${list.summary.substring(0, 50)}...`;
                            }
                        }
                    }
                } else {
                    return "";
                }
            },
            callWatchList() {
                this.store.getWatchList({}, false, "1");
            },
            AddWatchList(item) {
                if (!this.store.user.access_token) {
                    this.showLoginPopup = true;
                } else {
                    this.showLoginPopup = false;
                    if (this.isFollowed(item.id)) {
                        this.addWatchList(item, "DELETE");
                    } else {
                        this.addWatchList(item, "ADD");
                    }
                }
            },
            isFollowed(id) {
                if (this.store.watchList.length) {
                    let data = this.store.watchList.map((i) => i.id);
                    if (data.includes(parseInt(id))) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            },
            addWatchList(item, type) {
                let payload = {
                    item: item.id,
                    type: "MARKET",
                };
                let json = {};
                if (type == "ADD") {
                    json["id"] = item.id;
                    json["type"] = "MARKET";
                    json["currentPrice"] = item.currentPrice;
                    json["dailyPriceChangePercentage"] = item.dailyPriceChangePercentage;
                    json["description"] = item.description;
                    json["name"] = item.name;
                    json["chart"] = {};
                    let data = this.store.watchList;
                    data.push(json);
                    this.store.$patch({ watchList: data });
                } else if (type == "DELETE") {
                    let data = this.store.watchList.filter(
                        (i) => parseInt(i.id) != parseInt(item.id)
                    );
                    this.store.$patch({ watchList: data });
                }
                this.store.addToWatchList(payload, false, this, type);
            },
            changeChart(key) {
                if (Object.keys(this.store.assetDetail).length) {
                    let chart = [];
                    if (key == 1) {
                        if (
                            this.store.assetDetail.hourlyChart &&
                            this.store.assetDetail.hourlyChart.length
                        ) {
                            this.store.assetDetail.hourlyChart.map((i) => {
                                let temp = {
                                    date: new Date(
                                        moment(
                                            `${i.datetime[0]}-${i.datetime[1]}-${i.datetime[2]} ${i.datetime[3]}:00:00`,
                                            "YYYY-MM-DD HH:mm:ss"
                                        ).toISOString()
                                    ).getTime(),
                                    value: i.priceClose,
                                };
                                chart.push(temp);
                            });
                            this.drawMarketChart(`AssetChart${this.store.assetDetail.id}`, chart);
                        }
                    } else {
                        if (
                            this.store.assetDetail.dailyChart &&
                            this.store.assetDetail.dailyChart.length
                        ) {
                            this.store.assetDetail.dailyChart.map((i) => {
                                let temp = {
                                    date: new Date(
                                        moment(
                                            `${i.datetime[0]}-${i.datetime[1]}-${i.datetime[2]}`,
                                            "YYYY-MM-DD"
                                        ).format("YYYY-MM-DD")
                                    ).getTime(),
                                    value: i.priceClose,
                                };
                                chart.push(temp);
                            });
                            if (key == 7) {
                                chart = chart.slice(-5);
                            } else if (key == 30) {
                                chart = chart.slice(-30);
                            } else if (key == 365) {
                                chart = chart.slice(-365);
                            } else if (key == 10000) {
                                let startDate = moment().startOf("year");
                                let endDate = moment();
                                let data = [];
                                chart.map((i) => {
                                    if (moment(i.date).isBetween(startDate, endDate)) {
                                        data.push(i);
                                    }
                                });
                                chart = data;
                            }
                            this.drawMarketChart(`AssetChart${this.store.assetDetail.id}`, chart);
                        }
                    }
                }
            },
            checKLogin(type) {
                if (!this.store.user.access_token) {
                    this.showLoginPopup = true;
                } else {
                    this.showLoginPopup = false;
                    this.openPopup = type;
                }
            },
            closeTrade(item) {
                let form = {
                    requestedPrice: item.currentRate,
                };
                let conversionForm = {
                    currency: item.currencyName,
                    value: 0.01,
                    valueType: "AMOUNT",
                };
                this.store.getConverstionStats(conversionForm, false, (this.store.allTypeAccount) ? item.brokerAccountId : this.store.userSelectedAccount.brokerAccountId).then((res) => {
                    if (res) {
                        if (Object.keys(this.store.assetTradingConvesrionData).length) {
                            if (this.store.assetTradingConvesrionData.isClosed) {
                                this.toast.info("Cannot close trade when Market is closed");
                            } else {
                                this.store.closeOpenOrder(form, true, item.brokerTicket, item.brokerAccountId).then((response)=>{
                                    if(response === true){ 
                                        this.exitObject = {}
                                        item = {}
                                        this.store.getUserDashboardData({},true,'','10000')
                                    }
                                })
                            }
                        }
                    }
                })
            },
            formatPrice(price) {
                if (price < 0) {
                    return `-$${parseFloat(Math.abs(price)).toFixed(2)}`;
                } else {
                    return `$${parseFloat(price).toFixed(2)}`;
                }
            },
            formatTime(date, format, offset) {
                if (!date) return "N/A";
                let d = moment(
                    `${date.split(" ")[0]} ${date.split(" ")[1]}`,
                    "YYYY-MM-DD HH:mm:ss"
                );
                let tz = offset != 0 ? offset : -new Date().getTimezoneOffset();
                d.add(tz, "minutes");
                return d.fromNow();
            },
            dateFormat(date) {
                if (date) {
                    let format = moment(date).toString();
                    return moment(format).format("YYYY-MM-DD");
                }
            },
            timeFormat(date) {
                if (date) {
                    let format = moment(date).toString();
                    return moment(format).format("hh:mm:ss");
                }
            },
            getCurrecnyDetail() {
                let formData = {
                    from: "",
                    to: "",
                    marketIds: [this.CurrencyID],
                };
                formData["from"] = moment().subtract(1, "year").format("YYYY-MM-DD");
                formData["to"] = moment().format("YYYY-MM-DD");
                this.store.getAssetDetail(formData, true, this).then(() => {
                    if (!this.store.singleLoading && Object.keys(this.store.assetDetail).length) {
                        this.changeChart(365);
                    }
                });
            },
            getCurrecnyTrades() {
                let ID = "";
                if (
                    Object.keys(this.store.assetListWithID).length &&
                    this.store.assetListWithID.currencyNames &&
                    Object.keys(this.store.assetListWithID.currencyNames).length
                ) {
                    for (const key in this.store.assetListWithID.currencyNames) {
                        if (parseInt(this.CurrencyID) == parseInt(key)) {
                            ID = key;
                        }
                    }
                }
                if (ID) {
                    if (this.tradeType == "open") {
                        this.store.getAssetTrades({}, true, ID);
                    } else if (this.tradeType == "pending") {
                        this.store.getAssetPendingTrades({}, true, ID);
                    } else if (this.tradeType == "history") {
                        this.store.getAssetClosedTrades({}, true, ID);
                    }
                }
            },

            // getCurrenyNews() {
            //     if (this.$route.params.currency) {
            //         let form = {
            //             symbol: this.$route.params.currency.includes("-")
            //                 ? this.$route.params.currency.split("-").join("")
            //                 : this.$route.params.currency,
            //         };
            //         this.store.getCurrenyNews(form, true);
            //     }
            // },
            handleImgErr(val) {
                var fullSrc = val.target.src.split(".");
                var src = fullSrc[fullSrc.length - 2].split("/");
                var img = src[src.length - 1];
                if (!this.errImage.includes(img)) {
                    this.errImage.push(img);
                }
            },
            isImage(img) {
                return this.errImage.includes(img) ? false : true;
            },
            getMarketClose() {
                if (this.marketSelected.symbol) {
                    this.store.getMarketCloseDays({},true,this.marketSelected.symbol.includes("-")
                            ? this.marketSelected.symbol.split("-").join("/")
                            : this.marketSelected.symbol
                    );
                }
            },
            drawMarketChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                let root = am5.Root.new(id);
                root.setThemes([am5themes_Animated.new(root)]);

                root.dateFormatter.setAll({
                    dateFormat: this.dateDropSelected.key == 1 ? "dd MMM yyyy HH:mm" : "dd MMM yyyy",
                    dateFields: ["valueX"],
                });

                root.numberFormatter.set("numberFormat", "#,###.00");

                // Create drawprofitChart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: true,
                    })
                );

                // Create axes
                let xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        groupData: true,
                        baseInterval: {
                            timeUnit: this.dateDropSelected.key == 1 ? "hour" : "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 50,
                        }),
                        //tooltip: am5.Tooltip.new(root, {})
                    })
                );

                // xAxis.get("dateFormats")["day"] = "MM/dd";
                // xAxis.get("periodChangeDateFormats")["day"] = "MMMM";

                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                xAxis.get("renderer").grid.template.set("forceHidden", true);
                yAxis.get("renderer").grid.template.set("forceHidden", true);
                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({
                    stroke: am5.color(0x666666),
                    strokeWidth: 1,
                });

                chart.get("colors").set("colors", [am5.color(0xff7200)]);

                var legend = chart.bottomAxesContainer.children.push(
                    am5.Legend.new(root, {
                        x: am5.percent(50),
                        centerX: am5.percent(50),
                        layout: root.horizontalLayout,
                        useDefaultMarker: true,
                        clickTarget: "none",
                        // paddingTop: 15,
                    })
                );

                legend.labels.template.setAll({
                    fontSize: 12,
                    fontWeight: "600",
                });
                legend.markerRectangles.template.setAll({
                    cornerRadiusTL: 20,
                    cornerRadiusTR: 20,
                    cornerRadiusBL: 20,
                    cornerRadiusBR: 20,
                });
                legend.markers.template.setAll({
                    width: 13,
                    height: 13,
                });

                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        legendLabelText: this.store.assetDetail.symbol,
                        minBulletDistance: 10,
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "value",
                        valueXField: "date",
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText:
                                this.dateDropSelected.key == 1
                                    ? "[bold]Date&Time[/] : {valueX.formatDate('dd MMM yyyy HH:mm')} \n[bold]Price[/] : {valueY}"
                                    : "[bold]Date[/] : {valueX.formatDate('dd MMM yyyy')}  \n[bold]Price[/] : {valueY}",
                        }),
                    })
                );
                series.fills.template.setAll({
                    visible: true,
                });

                series.fills.template.set(
                    "fillGradient",
                    am5.LinearGradient.new(root, {
                        stops: [
                            {
                                opacity: 0.7,
                            },
                            {
                                opacity: 0.1,
                            },
                        ],
                    })
                );

                series.strokes.template.setAll({
                    strokeWidth: 2,
                });

                // Set data
                series.data.setAll(data);
                legend.data.push(series);

                // Make stuff animate on load
                series.appear(1000);

                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);

                xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#666666")),
                    fontSize: 10,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                    fontSize: 12,
                });
                var label2 = am5.Label.new(root, {
                    text: "[#666] Date/Time [/]",
                    x: am5.p50,
                    centerY: am5.p50,
                    fontSize: 12,
                });
                xAxis.children.push(label2);
                var label1 = am5.Label.new(root, {
                    rotation: -90,
                    text: "Close Price",
                    fill: am5.color(0x666666),
                    y: am5.p50,
                    centerX: am5.p50,
                    fontSize: 12,
                });
                yAxis.children.unshift(label1);

                chart.appear(1000, 100);
            },
        },
        updated(){
            this.$parent.calcHeight = this.$refs.getHeight.clientHeight
        },
        created() {
            if(this.marketSelected && this.marketSelected?.id){
                this.CurrencyID = this.marketSelected.id;
                this.getCurrecnyDetail();
                this.dateDropSelected  = { key: 365, value: "1Y" };
                if (this.store.user.access_token) {
                    this.getCurrecnyTrades();
                }
            }
            document.title = `Currency and Forex Trading with ZuluTrade - Follow and Copy Top Traders`;
            document
                .querySelector('meta[name="description"]')
                .setAttribute(
                    "content",
                    `Get detailed market information for major or exotic currencies, including charts, quotes, and news. Stay up-to-date with the latest market trends and make informed decisions.`
                );
        },
    };
</script>